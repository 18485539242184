/* Container (page width)
============================================= */
.bui-container {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 16px;
}
.bui-container--center {
    margin: 0 auto;
    max-width: var(--bui_container);
}
/* Grid
============================================= */
/**
    Based on https://codepen.io/serkai/pen/dMdzBd
    and https://codepen.io/serkai/pen/EoPoEd
    and http://nicolasgallagher.com/micro-clearfix-hack/
*/
.bui-grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: -8px -4px 0 -4px;
    clear: both; /* Fix for floated elements before the grid, that break due to negative margin */
}
[class*='bui-grid__column'] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    padding: 8px 4px 0 4px;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
}
.bui-grid--bleed {
    margin: 0;
}
.bui-grid--bleed > [class*='bui-grid__column'] {
    padding: 0;
}
.bui-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.bui-grid__column-1,
.bui-grid__column-1\/12 {
    -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
}
.bui-grid__column-2,
.bui-grid__column-2\/12 {
    -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
}
.bui-grid__column-3,
.bui-grid__column-1\/4 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    width: 25%;
    max-width: 25%;
}
.bui-grid__column-4,
.bui-grid__column-1\/3 {
    -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
}
.bui-grid__column-5,
.bui-grid__column-5\/12 {
    -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
}
.bui-grid__column-6,
.bui-grid__column-half,
.bui-grid__column-1\/2 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    width: 50%;
    max-width: 50%;
}
.bui-grid__column-7,
.bui-grid__column-7\/12 {
    -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
}
.bui-grid__column-8,
.bui-grid__column-2\/3 {
    -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
}
.bui-grid__column-9,
.bui-grid__column-3\/4 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    width: 75%;
    max-width: 75%;
}
.bui-grid__column-10,
.bui-grid__column-10\/12 {
    -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
}
.bui-grid__column-11,
.bui-grid__column-11\/12 {
    -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
}
.bui-grid__column-full,
.bui-grid__column-12,
.bui-grid__column-1\/1 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    width: 100%;
    max-width: 100%;
}
.bui-grid__column--offset-1 {
    margin-left: 8.3333333333%;
}
.bui-grid__column--offset-2 {
    margin-left: 16.6666666667%;
}
.bui-grid__column--offset-3 {
    margin-left: 25%;
}
.bui-grid__column--offset-4 {
    margin-left: 33.3333333333%;
}
.bui-grid__column--offset-5 {
    margin-left: 41.6666666667%;
}
.bui-grid__column--offset-6,
.bui-grid__column--offset-half {
    margin-left: 50%;
}
.bui-grid__column--offset-7 {
    margin-left: 58.3333333333%;
}
.bui-grid__column--offset-8 {
    margin-left: 66.6666666667%;
}
.bui-grid__column--offset-9 {
    margin-left: 75%;
}
.bui-grid__column--offset-10 {
    margin-left: 83.3333333333%;
}
.bui-grid__column--offset-11 {
    margin-left: 91.6666666667%;
}
/* Vertical alignment
============================================= */
.bui-grid--align-center { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.bui-grid--align-end    { -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
.bui-grid--align-start  { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
/* Horizontal alignment
============================================= */
.bui-grid--justify-center { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
.bui-grid--justify-space-between { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
.bui-grid--justify-space-around { -ms-flex-pack: distribute; justify-content: space-around; }
/* RTL
============================================= */
.bui-grid--reversed {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
/* Breakpoint switches (medium)
============================================= */
@media (min-width: 576px) {
    .bui-grid {
        margin: -16px -8px 0 -8px;
    }

    .bui-grid > [class*='bui-grid__column'] {
        padding: 16px 8px 0 8px;
    }

    .bui-grid--bleed {
        margin: 0;
    }

    .bui-grid--bleed > [class*='bui-grid__column'] {
        padding: 0;
    }

    .bui-grid__column-1\@medium,
    .bui-grid__column-1\/12\@medium {
        -ms-flex-preferred-size: 8.3333333333%;
            flex-basis: 8.3333333333%;
        width: 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .bui-grid__column-2\@medium,
    .bui-grid__column-2\/12\@medium {
        -ms-flex-preferred-size: 16.6666666667%;
            flex-basis: 16.6666666667%;
        width: 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .bui-grid__column-3\@medium,
    .bui-grid__column-1\/4\@medium {
        -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
        width: 25%;
        max-width: 25%;
    }

    .bui-grid__column-4\@medium,
    .bui-grid__column-1\/3\@medium {
        -ms-flex-preferred-size: 33.3333333333%;
            flex-basis: 33.3333333333%;
        width: 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .bui-grid__column-5\@medium,
    .bui-grid__column-5\/12\@medium {
        -ms-flex-preferred-size: 41.6666666667%;
            flex-basis: 41.6666666667%;
        width: 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .bui-grid__column-6\@medium,
    .bui-grid__column-half\@medium,
    .bui-grid__column-1\/2\@medium {
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
        width: 50%;
        max-width: 50%;
    }

    .bui-grid__column-7\@medium,
    .bui-grid__column-7\/12\@medium {
        -ms-flex-preferred-size: 58.3333333333%;
            flex-basis: 58.3333333333%;
        width: 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .bui-grid__column-8\@medium,
    .bui-grid__column-2\/3\@medium {
        -ms-flex-preferred-size: 66.6666666667%;
            flex-basis: 66.6666666667%;
        width: 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .bui-grid__column-9\@medium,
    .bui-grid__column-3\/4\@medium {
        -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
        width: 75%;
        max-width: 75%;
    }

    .bui-grid__column-10\@medium,
    .bui-grid__column-10\/12\@medium {
        -ms-flex-preferred-size: 83.3333333333%;
            flex-basis: 83.3333333333%;
        width: 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .bui-grid__column-11\@medium,
    .bui-grid__column-11\/12\@medium {
        -ms-flex-preferred-size: 91.6666666667%;
            flex-basis: 91.6666666667%;
        width: 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .bui-grid__column-full\@medium,
    .bui-grid__column-12\@medium,
    .bui-grid__column-1\/1\@medium {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        width: 100%;
        max-width: 100%;
    }

    .bui-grid__column--offset-1\@medium {
        margin-left: 8.3333333333%;
    }

    .bui-grid__column--offset-2\@medium {
        margin-left: 16.6666666667%;
    }

    .bui-grid__column--offset-3\@medium {
        margin-left: 25%;
    }

    .bui-grid__column--offset-4\@medium {
        margin-left: 33.3333333333%;
    }

    .bui-grid__column--offset-5\@medium {
        margin-left: 41.6666666667%;
    }

    .bui-grid__column--offset-6\@medium,
    .bui-grid__column--offset-half\@medium {
        margin-left: 50%;
    }

    .bui-grid__column--offset-7\@medium {
        margin-left: 58.3333333333%;
    }

    .bui-grid__column--offset-8\@medium {
        margin-left: 66.6666666667%;
    }

    .bui-grid__column--offset-9\@medium {
        margin-left: 75%;
    }

    .bui-grid__column--offset-10\@medium {
        margin-left: 83.3333333333%;
    }

    .bui-grid__column--offset-11\@medium {
        margin-left: 91.6666666667%;
    }
}
/* Breakpoint switches (large)
============================================= */
@media (min-width: 992px) {
    .bui-grid {
        margin: -24px -12px 0 -12px;
    }

    .bui-grid > [class*='bui-grid__column'] {
        padding: 24px 12px 0 12px;
    }

    .bui-grid--bleed {
        margin: 0;
    }

    .bui-grid--bleed > [class*='bui-grid__column'] {
        padding: 0;
    }

    .bui-grid__column-1\@large,
    .bui-grid__column-1\/12\@large {
        -ms-flex-preferred-size: 8.3333333333%;
            flex-basis: 8.3333333333%;
        width: 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .bui-grid__column-2\@large,
    .bui-grid__column-2\/12\@large {
        -ms-flex-preferred-size: 16.6666666667%;
            flex-basis: 16.6666666667%;
        width: 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .bui-grid__column-3\@large,
    .bui-grid__column-1\/4\@large {
        -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
        width: 25%;
        max-width: 25%;
    }

    .bui-grid__column-4\@large,
    .bui-grid__column-1\/3\@large {
        -ms-flex-preferred-size: 33.3333333333%;
            flex-basis: 33.3333333333%;
        width: 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .bui-grid__column-5\@large,
    .bui-grid__column-5\/12\@large {
        -ms-flex-preferred-size: 41.6666666667%;
            flex-basis: 41.6666666667%;
        width: 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .bui-grid__column-6\@large,
    .bui-grid__column-1\/2\@large {
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
        width: 50%;
        max-width: 50%;
    }

    .bui-grid__column-7\@large,
    .bui-grid__column-7\/12\@large {
        -ms-flex-preferred-size: 58.3333333333%;
            flex-basis: 58.3333333333%;
        width: 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .bui-grid__column-8\@large,
    .bui-grid__column-2\/3\@large {
        -ms-flex-preferred-size: 66.6666666667%;
            flex-basis: 66.6666666667%;
        width: 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .bui-grid__column-9\@large,
    .bui-grid__column-3\/4\@large {
        -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
        width: 75%;
        max-width: 75%;
    }

    .bui-grid__column-10\@large,
    .bui-grid__column-10\/12\@large {
        -ms-flex-preferred-size: 83.3333333333%;
            flex-basis: 83.3333333333%;
        width: 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .bui-grid__column-11\@large,
    .bui-grid__column-11\/12\@large {
        -ms-flex-preferred-size: 91.6666666667%;
            flex-basis: 91.6666666667%;
        width: 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .bui-grid__column-12\@large,
    .bui-grid__column-1\/1\@large {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        width: 100%;
        max-width: 100%;
    }

    .bui-grid__column--offset-1\@large {
        margin-left: 8.3333333333%;
    }

    .bui-grid__column--offset-2\@large {
        margin-left: 16.6666666667%;
    }

    .bui-grid__column--offset-3\@large {
        margin-left: 25%;
    }

    .bui-grid__column--offset-4\@large {
        margin-left: 33.3333333333%;
    }

    .bui-grid__column--offset-5\@large {
        margin-left: 41.6666666667%;
    }

    .bui-grid__column--offset-6,
    .bui-grid__column--offset-half\@large {
        margin-left: 50%;
    }

    .bui-grid__column--offset-7\@large {
        margin-left: 58.3333333333%;
    }

    .bui-grid__column--offset-8\@large {
        margin-left: 66.6666666667%;
    }

    .bui-grid__column--offset-9\@large {
        margin-left: 75%;
    }

    .bui-grid__column--offset-10\@large {
        margin-left: 83.3333333333%;
    }

    .bui-grid__column--offset-11\@large {
        margin-left: 91.6666666667%;
    }
}
/* Breakpoint switches (huge)
============================================= */
@media (min-width: 1170px) {
    .bui-grid {
        margin: -32px -16px 0 -16px;
    }

    .bui-grid > [class*='bui-grid__column'] {
        padding: 32px 16px 0 16px;
    }

    .bui-grid--bleed {
        margin: 0;
    }

    .bui-grid--bleed > [class*='bui-grid__column'] {
        padding: 0;
    }


    .bui-grid__column-1\@huge,
    .bui-grid__column-1\/12\@huge {
        -ms-flex-preferred-size: 8.3333333333%;
            flex-basis: 8.3333333333%;
        width: 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .bui-grid__column-2\@huge,
    .bui-grid__column-2\/12\@huge {
        -ms-flex-preferred-size: 16.6666666667%;
            flex-basis: 16.6666666667%;
        width: 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .bui-grid__column-3\@huge,
    .bui-grid__column-1\/4\@huge {
        -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
        width: 25%;
        max-width: 25%;
    }

    .bui-grid__column-4\@huge,
    .bui-grid__column-1\/3\@huge {
        -ms-flex-preferred-size: 33.3333333333%;
            flex-basis: 33.3333333333%;
        width: 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .bui-grid__column-5\@huge,
    .bui-grid__column-5\/12\@huge {
        -ms-flex-preferred-size: 41.6666666667%;
            flex-basis: 41.6666666667%;
        width: 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .bui-grid__column-6\@huge,
    .bui-grid__column-1\/2\@huge {
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
        width: 50%;
        max-width: 50%;
    }

    .bui-grid__column-7\@huge,
    .bui-grid__column-7\/12\@huge {
        -ms-flex-preferred-size: 58.3333333333%;
            flex-basis: 58.3333333333%;
        width: 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .bui-grid__column-8\@huge,
    .bui-grid__column-2\/3\@huge {
        -ms-flex-preferred-size: 66.6666666667%;
            flex-basis: 66.6666666667%;
        width: 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .bui-grid__column-9\@huge,
    .bui-grid__column-3\/4\@huge {
        -ms-flex-preferred-size: 75%;
            flex-basis: 75%;
        width: 75%;
        max-width: 75%;
    }

    .bui-grid__column-10\@huge,
    .bui-grid__column-10\/12\@huge {
        -ms-flex-preferred-size: 83.3333333333%;
            flex-basis: 83.3333333333%;
        width: 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .bui-grid__column-11\@huge,
    .bui-grid__column-11\/12\@huge {
        -ms-flex-preferred-size: 91.6666666667%;
            flex-basis: 91.6666666667%;
        width: 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .bui-grid__column-12\@huge,
    .bui-grid__column-1\/1\@huge {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        width: 100%;
        max-width: 100%;
    }

    .bui-grid__column--offset-1\@huge {
        margin-left: 8.3333333333%;
    }

    .bui-grid__column--offset-2\@huge {
        margin-left: 16.6666666667%;
    }

    .bui-grid__column--offset-3\@huge {
        margin-left: 25%;
    }

    .bui-grid__column--offset-4\@huge {
        margin-left: 33.3333333333%;
    }

    .bui-grid__column--offset-5\@huge {
        margin-left: 41.6666666667%;
    }

    .bui-grid__column--offset-6,
    .bui-grid__column--offset-half\@huge {
        margin-left: 50%;
    }

    .bui-grid__column--offset-7\@huge {
        margin-left: 58.3333333333%;
    }

    .bui-grid__column--offset-8\@huge {
        margin-left: 66.6666666667%;
    }

    .bui-grid__column--offset-9\@huge {
        margin-left: 75%;
    }

    .bui-grid__column--offset-10\@huge {
        margin-left: 83.3333333333%;
    }

    .bui-grid__column--offset-11\@huge {
        margin-left: 91.6666666667%;
    }
}
