:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.bui-dropdown__content {
    display: none;
    position: absolute;
    left: 0;
    min-width: 100%;
    top: 100%;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    z-index: 200;
}
.rtl .bui-dropdown__content,
[dir="rtl"] .bui-dropdown__content {
    left: auto;
    right: 0;
}
.bui-dropdown__more {
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    opacity: 0.5;
    padding: 0;
}
.bui-dropdown__more:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-dropdown__more:hover {
    opacity: 0.7;
}
.bui-dropdown--active .bui-dropdown__more {
    opacity: 1;
}
.bui-dropdown__more + .bui-dropdown__content {
    left: auto;
    right: 0;
}
.rtl .bui-dropdown__more + .bui-dropdown__content,
[dir="rtl"] .bui-dropdown__more + .bui-dropdown__content {
    left: 0;
    right: auto;
}
.bui-dropdown--active .bui-dropdown__content {
    display: block;
}
.bui-dropdown--end .bui-dropdown__content {
    left: auto;
    right: 0;
}
.rtl .bui-dropdown--end .bui-dropdown__content,
[dir="rtl"] .bui-dropdown--end .bui-dropdown__content {
    right: auto;
    left: 0;
}
