:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-icon {
    display: inline-block;
    fill: currentColor;
}
.bui-icon svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
}
.bui-icon--smaller {
    width: 12px;
    height: 12px;
}
.bui-icon--small {
    width: 14px;
    height: 14px;
}
.bui-icon--medium {
    width: 16px;
    height: 16px;
}
.bui-icon--large {
    width: 20px;
    height: 20px;
}
.bui-icon--larger {
    width: 24px;
    height: 24px;
}
.bui-icon--largest {
    width: 32px;
    height: 32px;
}
.bui-icon--color-white {
    color: #fff;
    fill: #fff;
}
.bui-icon--color-black {
    color: #000;
    fill: #000;
}
.bui-icon--color-action {
    color: #0071C2;
    fill: #0071C2;
}
/* destructive */
.bui-icon--color-destructive-dark {
    color: #A30000;
    fill: #A30000;
}
.bui-icon--color-destructive {
    color: #CC0000;
    fill: #CC0000;
}
.bui-icon--color-destructive-light {
    color: #FCB4B4;
    fill: #FCB4B4;
}
.bui-icon--color-destructive-lighter {
    color: #FFEBEB;
    fill: #FFEBEB;
}
.bui-icon--color-destructive-lightest {
    color: #FFF0F0;
    fill: #FFF0F0;
}
/* callout */
.bui-icon--color-callout-dark {
    color: #BC5B01;
    fill: #BC5B01;
}
.bui-icon--color-callout {
    color: #FF8000;
    fill: #FF8000;
}
.bui-icon--color-callout-light {
    color: #FFC489;
    fill: #FFC489;
}
.bui-icon--color-callout-lighter {
    color: #FFF0E0;
    fill: #FFF0E0;
}
.bui-icon--color-callout-lightest {
    color: #FFF8F0;
    fill: #FFF8F0;
}
/* complement */
.bui-icon--color-complement-dark {
    color: #CD8900;
    fill: #CD8900;
}
.bui-icon--color-complement {
    color: #FEBB02;
    fill: #FEBB02;
}
.bui-icon--color-complement-light {
    color: #FFE08A;
    fill: #FFE08A;
}
.bui-icon--color-complement-lighter {
    color: #FDF4D8;
    fill: #FDF4D8;
}
.bui-icon--color-complement-lightest {
    color: #FEFBF0;
    fill: #FEFBF0;
}
/* constructive */
.bui-icon--color-constructive-dark {
    color: #006607;
    fill: #006607;
}
.bui-icon--color-constructive {
    color: #008009;
    fill: #008009;
}
.bui-icon--color-constructive-light {
    color: #97E59C;
    fill: #97E59C;
}
.bui-icon--color-constructive-lighter {
    color: #E7FDE9;
    fill: #E7FDE9;
}
.bui-icon--color-constructive-lightest {
    color: #F1FEF2;
    fill: #F1FEF2;
}
/* primary */
.bui-icon--color-primary-dark {
    color: #00224F;
    fill: #00224F;
}
.bui-icon--color-primary {
    color: #003580;
    fill: #003580;
}
.bui-icon--color-primary-light {
    color: #BAD4F7;
    fill: #BAD4F7;
}
.bui-icon--color-primary-lighter {
    color: #EBF3FF;
    fill: #EBF3FF;
}
.bui-icon--color-primary-lightest {
    color: #FAFCFF;
    fill: #FAFCFF;
}
/* grayscale */
.bui-icon--color-grayscale-dark {
    color: #333333;
    fill: #333333;
}
.bui-icon--color-grayscale {
    color: #6B6B6B;
    fill: #6B6B6B;
}
.bui-icon--color-grayscale-light {
    color: #BDBDBD;
    fill: #BDBDBD;
}
.bui-icon--color-grayscale-lighter {
    color: #E6E6E6;
    fill: #E6E6E6;
}
.bui-icon--color-grayscale-lightest {
    color: #F5F5F5;
    fill: #F5F5F5;
}
