:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid;
    padding: 16px;
    border-radius: 2px;
}
.bui-alert__icon {
    width: 24px;
    height: 24px;
}
.bui-alert__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    padding-left: 16px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.rtl .bui-alert__description,
[dir="rtl"] .bui-alert__description {
    padding-left: 0;
    padding-right: 16px;
}
.bui-alert__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
    display: block;
    margin-bottom: 8px;
}
.bui-alert__title:last-child {
    margin-bottom: 0;
}
.bui-alert__text {
    margin: 0 0 16px;
}
.bui-alert__text:first-child {
    margin-top: 2px;
}
.bui-alert__text:last-child {
    margin-bottom: 0;
}
/* Styles: Info */
.bui-alert--info {
    background-color: #FEFBF0;
    border-color: #FFE08A;
}
.bui-alert--info .bui-alert__icon {
    fill: #CD8900;
    color: #CD8900;
}
/* Styles: Error */
.bui-alert--error {
    background-color: #FFF0F0;
    border-color: #FCB4B4;
}
.bui-alert--error .bui-alert__icon {
    fill: #CC0000;
    color: #CC0000;
}
/* Styles: Success */
.bui-alert--success {
    background-color: #F1FEF2;
    border-color: #97E59C;
}
.bui-alert--success .bui-alert__icon {
    fill: #006607;
    color: #006607;
}
/* Styles: Inline */
.bui-alert--inline {
    padding: 0;
    border: none;
    background: none;
}
.bui-alert--inline .bui-alert__description {
    padding-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.rtl .bui-alert--inline .bui-alert__description,
[dir="rtl"] .bui-alert--inline .bui-alert__description {
    padding-left: 0;
    padding-right: 8px;
}
.bui-alert--inline.bui-alert--info {
    color: #333333;
}
.bui-alert--inline.bui-alert--error {
    color: #CC0000;
}
.bui-alert--inline.bui-alert--success {
    color: #008009;
}
