.bui-f-font-defaults,
.bui_font_defaults {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
}
.bui-f-font-defaults h1,
.bui_font_defaults h1{
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.bui-f-font-defaults h2,
.bui_font_defaults h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.bui-f-font-defaults h3,
.bui_font_defaults h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.bui-f-font-defaults h4,
.bui_font_defaults h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.bui-f-font-defaults h5,
.bui_font_defaults h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.bui-f-font-defaults h6,
.bui_font_defaults h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.bui-f-font-defaults input,
.bui-f-font-defaults textarea,
.bui-f-font-defaults button,
.bui-f-font-defaults select,
.bui_font_defaults input,
.bui_font_defaults textarea,
.bui_font_defaults button,
.bui_font_defaults select {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
}
body .bui-f-font-display_three,
body .bui_font_display_three {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}
body .bui-f-font-display_two,
body .bui_font_display_two {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
body .bui-f-font-display_one,
body .bui_font_display_one {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
body .bui-f-font-heading,
body .bui_font_heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
body .bui-f-font-featured,
body .bui_font_featured {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
body .bui-f-font-strong,
body .bui_font_strong {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
body .bui-f-font-emphasized,
body .bui_font_emphasized {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
body .bui-f-font-body,
body .bui_font_body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
body .bui-f-font-caption,
body .bui_font_caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
