:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-checkbox {
    position: relative;
    display: block;
}
.bui-checkbox__input {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
}
.bui-checkbox__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    display: block;
    position: relative;
    min-height: 14px;
    padding: 0 0 0 28px;
    cursor: pointer;
}
.rtl .bui-checkbox__label,
[dir="rtl"] .bui-checkbox__label {
    padding: 0 28px 0 0;
}
.bui-checkbox__label:before,
.bui-checkbox__label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
}
.bui-checkbox__label:before {
    background-color: #fff;
    border: 2px #6B6B6B solid;
    border-radius: 2px;
}
.rtl .bui-checkbox__label:before,
[dir="rtl"] .bui-checkbox__label:before,
.rtl .bui-checkbox__label:after,
[dir="rtl"] .bui-checkbox__label:after {
    left: auto;
    right: 0;
}
/* Error state */
.bui-has-error .bui-checkbox__label:before,
.bui-has-error .bui-checkbox__label:after {
    border-color: #CC0000;
}
/* Indeterminate state */
.bui-checkbox__input:indeterminate ~ .bui-checkbox__label:before,
.bui-checkbox__input--indeterminate ~ .bui-checkbox__label:before {
    border: none;
    background-color: #0071C2;
}
.bui-checkbox__input:indeterminate ~ .bui-checkbox__label:after,
.bui-checkbox__input--indeterminate ~ .bui-checkbox__label:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 4px;
    width: 12px;
    height: 2px;
    background: #fff;
    border-radius: 1px;
}
.rtl .bui-checkbox__input:indeterminate ~ .bui-checkbox__label:after,
.rtl .bui-checkbox__input--indeterminate ~ .bui-checkbox__label:after,
[dir="rtl"] .bui-checkbox__input:indeterminate ~ .bui-checkbox__label:after,
[dir="rtl"] .bui-checkbox__input--indeterminate ~ .bui-checkbox__label:after {
    left: auto;
    right: 4px;
}
/* Checked state */
.bui-checkbox__input:checked ~ .bui-checkbox__label:before {
    border-color: #0071C2;
    background: #0071C2;
}
.bui-checkbox__input:checked ~ .bui-checkbox__label:after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 128 128' fill='%23fff'%3E%3Cpath d='M56.33,102a6,6,0,0,1-4.24-1.75L19.27,67.54A6,6,0,1,1,27.74,59L55.68,86.88l44-58.49a6,6,0,1,1,9.58,7.22l-48.17,64A6,6,0,0,1,56.75,102Z' data-name='8x'/%3E%3C/svg%3E");
    /* Indeterminate overrides */
    background: none;
    top: 2px;
    left: 2px;
}
.rtl .bui-checkbox__input:checked ~ .bui-checkbox__label:after,
.rtl .bui-checkbox__input:checked ~ .bui-checkbox__label:after {
    left: auto;
    right: 2px;
}
/* Disabled state */
.bui-checkbox__input:disabled ~ .bui-checkbox__label:before {
    border-color: #BDBDBD;
    background-color: #fff;
}
.bui-checkbox__input:disabled ~ .bui-checkbox__label:before,
.bui-checkbox__input:disabled ~ .bui-checkbox__label:after {
    cursor: not-allowed;
}
.bui-checkbox__input:disabled:checked ~ .bui-checkbox__label:before,
.bui-checkbox__input:disabled:indeterminate ~ .bui-checkbox__label:before,
.bui-checkbox__input--indeterminate:disabled ~ .bui-checkbox__label:before {
    background: #BDBDBD;
}
.bui-checkbox__input:disabled ~ .bui-checkbox__label {
    color: #BDBDBD;
    cursor: not-allowed;
}
/* Focus state */
.bui-checkbox__input:focus ~ .bui-checkbox__label:before {
    border-color: #0071C2;
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-has-error .bui-checkbox__input:not(:checked):focus ~ .bui-checkbox__label:before {
    border-color: #A30000;
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
          box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
}

