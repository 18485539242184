:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-dropdown-menu {
    padding: 16px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
  z-index: 100;
  -webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .12),
        0 2px 3px -1px rgba(0, 0, 0, .2);
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .12),
        0 2px 3px -1px rgba(0, 0, 0, .2);
}
.bui-dropdown-menu__items {
    list-style-type: none;
    margin: -16px;
    padding: 0;
}
.bui-dropdown-menu__button {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    outline: none;
}
.bui-dropdown-menu__button,
.bui-dropdown-menu__button:visited,
.bui-dropdown-menu__button:link {
    border: none;
    margin: 0;
    cursor: pointer;
    padding: 8px 16px;
    white-space: nowrap;
    text-decoration: none;
    color: #333333;
    background-color: #fff;
    fill: #333333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-dropdown-menu__button:hover,
.bui-dropdown-menu__button:focus {
    background-color: #F5F5F5;
}
.bui-dropdown-menu__button:active {
    background-color: #E6E6E6;
}
.rtl .bui-dropdown-menu__button,
[dir="rtl"] .bui-dropdown-menu__button {
    text-align: right;
}
.bui-dropdown-menu__button[disabled],
.bui-dropdown-menu__button[disabled]:visited,
.bui-dropdown-menu__button[disabled]:link {
    fill: #BDBDBD;
    color: #BDBDBD;
    cursor: not-allowed;
}
.bui-dropdown-menu__button[disabled]:hover,
.bui-dropdown-menu__button[disabled]:focus,
.bui-dropdown-menu__button[disabled]:active {
    background: none;
}
.bui-dropdown-menu__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: middle;
}
.rtl .bui-dropdown-menu__icon,
[dir="rtl"] .bui-dropdown-menu__icon {
    margin-right: 0;
    margin-left: 8px;
}
.bui-dropdown-menu__text {
    line-height: 24px;
    height: 24px;
}
.bui-dropdown-menu__text-slot {
    margin-left: 8px;
}
.rtl .bui-dropdown-menu__text-slot,
[dir="rtl"] .bui-dropdown-menu__text-slot {
    margin-left: 0;
    margin-right: 8px;
}
.bui-dropdown-menu__end-slot {
    padding-left: 8px;
    margin-left: auto;
}
.rtl .bui-dropdown-menu__end-slot,
[dir="rtl"] .bui-dropdown-menu__end-slot {
    margin-left: 0;
    margin-right: auto;
    padding-left: 0;
    padding-right: 8px;
}
.bui-dropdown-menu--fill {
    padding: 0;
}
.bui-dropdown-menu--fill .bui-dropdown-menu__items {
    margin: 0;
}
