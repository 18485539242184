:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-input-text__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    z-index: 0;
}
.bui-input-text__field {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative;
}
.bui-input-text__addon {
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    color: #6B6B6B;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
    z-index: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-input-text__addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
.rtl .bui-input-text__addon:first-child,
[dir="rtl"] .bui-input-text__addon:first-child {
    border-radius: 0 2px 2px 0;
    border-right: 1px solid #E6E6E6;
    border-left: 0;
}
.bui-input-text__addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
.rtl .bui-input-text__addon:last-child,
[dir="rtl"] .bui-input-text__addon:last-child {
    border-radius: 2px 0 0 2px;
    border-left: 1px solid #E6E6E6;
    border-right: 0;
}
.bui-input-text__field {
    position: relative;
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}
.bui-input-text__field:first-child .bui-input-text__decorator {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.rtl .bui-input-text__field:first-child .bui-input-text__decorator,
[dir="rtl"] .bui-input-text__field:first-child .bui-input-text__decorator {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.bui-input-text__field:last-child .bui-input-text__decorator {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.rtl .bui-input-text__field:last-child .bui-input-text__decorator,
[dir="rtl"] .bui-input-text__field:last-child .bui-input-text__decorator {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.bui-input-text__decorator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #E6E6E6;
  display: block;
  font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
    border-radius: 0;
}
.rtl .bui-input-text__field .bui-input-text__decorator,
[dir="rtl"] .bui-input-text__field .bui-input-text__decorator {
    border-radius: 0;
}
.bui-input-text__control {
    position: relative;
    z-index: 5;
    margin: 0; /* Reset for Safari */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    background: none;
    display: block;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    border: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding: 9px; /* simulate 1px border */
    outline: none;
}
.bui-input-text__control:focus + .bui-input-text__decorator {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
  border-color: #0071C2;
}
.bui-input-text__control[disabled] + .bui-input-text__decorator {
  cursor: not-allowed;
  color: #BDBDBD;
  background-color: #F5F5F5;
}
.bui-input-text__side {
    position: relative;
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-input-text__side:empty {
    display: none;
}
.bui-input-text__side:first-child {
    padding: 8px 0 8px 8px;
}
.rtl .bui-input-text__side:first-child,
[dir="rtl"] .bui-input-text__side:first-child {
    padding: 8px 8px 8px 0;
}
.bui-input-text__side:last-child {
    padding: 8px 8px 8px 0;
}
.rtl .bui-input-text__side:last-child,
[dir="rtl"] .bui-input-text__side:last-child {
    padding: 8px 0 8px 8px;
}
.bui-has-error .bui-input-text__decorator,
.bui-has-error .bui-input-text__addon {
  border-color: #CC0000;
}
.bui-has-error .bui-input-text__control:focus + .bui-input-text__decorator {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
          box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
  border-color: #CC0000;
}
