:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-button {
    border: 1px solid #0071C2;
    border-radius: 2px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 8px 16px;
    cursor: pointer;
    font-family: inherit;
    text-decoration: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    vertical-align: middle;
    position: relative;
    color: #fff;
    background-color: #0071C2;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.bui-button:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-button:focus,
.bui-button:hover {
    text-decoration: none;
}
.bui-button[disabled] {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
    cursor: not-allowed;
}
.bui-button:hover,
.bui-button:visited,
.bui-button:focus,
.bui-button:active,
.bui-button.bui-button--active,
.bui-button:link,
.bui-button[disabled] {
    color: #fff;
    /* Covers modifiers specificity, removes product overrides */
    text-decoration: none;
}
.bui-button:hover {
    background-color: #005C9D;
    border-color: #005C9D;
}
.bui-button[disabled]:hover {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
}
.bui-button:active,
.bui-button.bui-button--active {
    background-color: #005C9D;
    border-color: #005C9D;
}
.bui-button__icon {
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-right: 8px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.bui-button .bui-button__icon:only-child {
    margin: 0 -4px;
}
.bui-button__icon--end {
    margin-left: 8px;
    margin-right: 0;
}
.rtl .bui-button__icon,
[dir="rtl"] .bui-button__icon {
    margin-right: 0;
    margin-left: 8px;
}
.rtl .bui-button__icon--end {
    margin-right: 8px;
    margin-left: 0;
}
.bui-button__text {
    display: inline-block;
    vertical-align: middle;
}
.bui-button__text:first-child,
.rtl .bui-button__text:first-child,
[dir="rtl"] .bui-button__text:first-child {
    vertical-align: baseline;
}
.bui-button__loader {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
/* Type modifiers */
.bui-button--secondary {
    background-color: #fff;
    color: #0071C2;
    border-color: #0071C2;
}
.bui-button--secondary:hover,
.bui-button--secondary:visited,
.bui-button--secondary:focus,
.bui-button--secondary:active,
.bui-button--secondary.bui-button--active,
.bui-button--secondary:link {
    color: #0071C2;
}
.bui-button--secondary[disabled] {
    background-color: #fff;
    color: #BDBDBD;
    border-color: #BDBDBD;
}
.bui-button--secondary:hover {
    background-color: #E4F4FF;
}
.bui-button--secondary[disabled]:active,
.bui-button--secondary[disabled].bui-button--active,
.bui-button--secondary[disabled]:hover {
    color: #BDBDBD;
    border-color: #BDBDBD;
    background-color: #fff;
}
.bui-button--secondary:active,
.bui-button--secondary.bui-button--active {
    background-color: #E4F4FF;
}
.bui-button--tertiary {
    background-color: transparent;
    color: #0071C2;
    border-color: transparent;
}
.bui-button--tertiary:hover,
.bui-button--tertiary:visited,
.bui-button--tertiary:focus,
.bui-button--tertiary:active,
.bui-button--tertiary.bui-button--active,
.bui-button--tertiary:link {
    color: #0071C2;
}
.bui-button--tertiary[disabled] {
    background-color: transparent;
    color: #BDBDBD;
    border-color: transparent;
}
.bui-button--tertiary:hover {
    background-color: #E4F4FF;
    border-color: #E4F4FF;
}
.bui-button--tertiary[disabled]:active,
.bui-button--tertiary[disabled].bui-button--active,
.bui-button--tertiary[disabled]:hover {
    color: #BDBDBD;
    border-color: transparent;
    background-color: #fff;
}
.bui-button--tertiary:active,
.bui-button--tertiary.bui-button--active {
    background-color: #E4F4FF;
    border-color: #E4F4FF;
}
.bui-button--destructive {
    background-color: #CC0000;
    color: #fff;
    border-color: #CC0000;
}
.bui-button--destructive:hover,
.bui-button--destructive:visited,
.bui-button--destructive:focus,
.bui-button--destructive:active,
.bui-button--destructive.bui-button--active,
.bui-button--destructive:link,
.bui-button--destructive[disabled] {
    color: #fff;
}
.bui-button--destructive:hover {
    background-color: #A30000;
    border-color: #A30000;
}
.bui-button--destructive[disabled]:hover {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
}
.bui-button--destructive:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
          box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
}
.bui-button--destructive:active,
.bui-button--destructive.bui-button--active {
    background-color: #A30000;
    border-color: #A30000;
}
/* Size modifiers */
.bui-button--large {
    padding: 12px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
    font-weight: 500;
}
.bui-button--large > .bui-button__icon {
    width: 24px;
    height: 24px;
}
.bui-button--large .bui-button__icon:only-child {
    margin: 0 -8px;
}
.bui-button--wide {
    width: 100%;
}
.bui-button--icon-only {
    padding-left: 8px;
    padding-right: 8px;
}
.bui-button--icon-only.bui-button--large {
    padding-left: 16px;
    padding-right: 16px;
}
/* Loading */
.bui-button.bui-is-loading,
.bui-button.is-loading {
    background-color: #005C9D;
    border-color: #005C9D;
}
.bui-button.bui-is-loading[disabled],
.bui-button.is-loading[disabled] {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
}
.bui-button--secondary.bui-is-loading,
.bui-button--secondary.is-loading {
    background-color: #fff;
}
.bui-button--secondary.bui-is-loading[disabled],
.bui-button--secondary.is-loading[disabled] {
    background-color: #fff;
    border-color: #BDBDBD;
}
.bui-button--destructive.bui-is-loading,
.bui-button--destructive.is-loading {
    background-color: #A30000;
    border-color: #A30000;
}
.bui-button--destructive.bui-is-loading[disabled],
.bui-button--destructive.is-loading[disabled] {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
}
.bui-button.bui-is-loading > .bui-button__loader,
.bui-button.is-loading > .bui-button__loader {
    display: block;
}
.bui-button.bui-is-loading > .bui-button__text,
.bui-button.is-loading > .bui-button__icon {
    visibility: hidden;
}
