:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-toast {
    position: fixed;
    left: 24px;
    bottom: 24px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 2px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 16px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 320px;
    z-index: 400;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.rtl .bui-toast,
[dir="rtl"] .bui-toast {
    right: 24px;
}
.bui-toast__button {
    color: #A3D7FC;
    margin-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-toast__button:hover {
    color: #A3D7FC;
}
.rtl .bui-toast__button,
[dir="rtl"] .bui-toast__button {
    margin-left: 0;
    margin-right: 16px;
}
@media (max-width: 575px) {
    .bui-toast {
        width: auto;
        left: 16px;
        right: 16px;
        bottom: 16px;
    }
}
/* Animations on by default */
.bui-toast--enter-from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
}
.bui-toast--enter-settings {
    -webkit-transition: opacity 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.bui-toast--exit-to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
}
.bui-toast--exit-settings {
    -webkit-transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (prefers-reduced-motion: reduce) {
    .bui-toast--enter-settings,
    .bui-toast--exit-settings {
        -webkit-transition-duration: 0s !important;
                transition-duration: 0s !important;
    }

    .bui-toast--enter-settings {
        -webkit-transition-delay: 98ms;
                transition-delay: 98ms;
    }
}
