:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 2px;
}
.bui-table__head {
    display: none;
}
.bui-table,
.bui-table__body,
.bui-table__row,
.bui-table__cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    display: block;
}
.bui-table__row {
    position: relative;
    margin-top: 8px;
}
.bui-table__row:first-child {
    margin-top: 0;
}
.bui-table__cell:before {
    content: attr(data-heading);
    display: inline-block;
    width: 50%;
    position: absolute;
    padding-right: 16px;
    padding-left: 16px;
    left: 0;
}
.rtl .bui-table__cell:before,
[dir="rtl"] .bui-table__cell:before {
    left: auto;
    right: 0;
}
.bui-table__cell {
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    padding: 16px;
}
@-moz-document url-prefix() {
    .bui-table__cell {
        background-clip: padding-box;
    }
}
.bui-table__cell--row-head:before {
    display: none;
}
.bui-table__cell--row-head {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
    text-align: left;
}
.rtl .bui-table__cell--row-head,
[dir="rtl"] .bui-table__cell--row-head {
    text-align: right;
}
.bui-table__cell--no-head:before {
    display: none;
}
.bui-table__cell--empty {
    padding-top: 32px;
    padding-bottom: 32px;
    color: #6B6B6B;
    text-align: center;
}
.bui-table__cell--check,
.bui-table__cell--more {
    border: 0;
    background-color: transparent;
}
.bui-table__row-toggle {
    border: 1px solid #E6E6E6;
    border-top: 0;
    display: block;
}
.bui-table__row-toggle-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #0071C2;
    text-decoration: none;
}
.bui-table__row-toggle-link:hover {
    background: #F5F5F5;
}
.bui-table__row-toggle-cell {
    display: block;
    padding: 0;
}
.bui-table__row--expanded .bui-table__cell--collapsible {
    display: block;
}
.bui-table--initialized .bui-table__cell--collapsible {
    display: none;
}
.bui-table--initialized .bui-table__row--expanded .bui-table__cell--collapsible {
    display: block;
}
/* Selectable table */
.bui-table__row--selected .bui-table__cell {
    background-color: #EBF3FF;
}
.bui-table__row--selected .bui-table__cell--check,
.bui-table__row--selected .bui-table__cell--more {
    background-color: transparent;
}
.bui-table__cell--more {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
}
.rtl .bui-table__cell--more,
[dir="rtl"] .bui-table__cell--more {
    right: auto;
    left: 0;
}
.bui-table__cell--more .bui-dropdown__button--more {
    -webkit-transform: translate(0, 8px);
            transform: translate(0, 8px);
}
.bui-table__cell--more:before {
    display: none;
}
.bui-table__cell--check {
    float: left;
}
.rtl .bui-table__cell--check,
[dir="rtl"] .bui-table__cell--check {
    float: right;
}
/* Expandable table */
.bui-table__row--expandable.bui-table__row {
    border-bottom: none;
    margin-bottom: 0;
    margin-top: 8px;
}
.bui-table__row--expandable.bui-table__row:first-child {
    margin-top: 0;
}
.bui-table__expand-icon,
.bui-table__row-toggle svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    display: none;
    cursor: pointer;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.rtl .bui-table__expand-icon,
.rtl .bui-table__row-toggle svg,
[dir="rtl"] .bui-table__expand-icon,
[dir="rtl"].bui-table__row-toggle svg {
    margin-right: 0;
    margin-left: 4px;
}
.bui-table__row-toggle svg {
    display: inline-block;
}
.bui-table__row--expanded .bui-table__expand-icon,
.bui-table__row--expanded + .bui-table__row-toggle svg,
.bui-table__row--expanded + .bui-table__row-expansion + .bui-table__row-toggle svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
}
.bui-table__row-expansion {
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    display: block;
}
.bui-table__row-expansion > td {
    padding: 0;
}
.bui-table__row-expansion-content {
    max-height: 0;
    overflow: hidden;
}
.bui-table__row--expanded + .bui-table__row-expansion {
    border-bottom: 1px solid #E6E6E6;
}
.bui-table__row--expanded + .bui-table__row-expansion .bui-table__row-expansion-content {
    max-height: none;
    padding: 16px;
}
.bui-table__cell--expand-trigger {
    float: right;
}
.rtl .bui-table__cell--expand-trigger,
[dir="rtl"] .bui-table__cell--expand-trigger {
    float: left;
}
.bui-table__cell--expand-trigger:before {
    display: none;
}
.bui-table__cell--check:before {
    display: none;
}
.bui-table__row--expandable .bui-table__cell--row-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
/* min-width fixes IE wrongly applying this query */
@media (max-width: 575px) {
    .bui-table__cell {
        min-height: 52px;
        padding: 16px 16px 16px 50%;
    }

    .rtl .bui-table__cell,
    [dir="rtl"] .bui-table__cell {
        padding-left: 16px;
        padding-right: 50%;
    }

    .bui-table__cell--more {
        padding: 16px 8px;
    }

    .bui-table__cell--row-head {
        border-top: 1px solid #E6E6E6;
    }

    .bui-table__cell--row-head,
    .bui-table__cell--check {
        padding-left: 16px;
    }

    .rtl .bui-table__cell--row-head,
    .rtl .bui-table__cell--check,
    [dir="rtl"] .bui-table__cell--row-head,
    [dir="rtl"] .bui-table__cell--check {
        padding-right: 16px;
    }
}
/* IE 10/11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .bui-table__cell:before {
        content: attr(data-heading);
        display: block;
    }

    .bui-table__head {
        display: none;
    }

    .bui-table__cell--row-head:before {
        display: none;
    }

    .bui-table__cell--no-head:before {
        display: none;
    }

    .bui-table__cell--more {
        display: none;
    }
}
/* Desktop */
@media (min-width: 576px) {
    .bui-table {
        display: table;
        border: 1px solid #E6E6E6;
        border-bottom: 0;
        background-color: #fff;
    }

    .bui-table__head,
    .bui-table__body {
        display: table-row-group;
    }

    .bui-table__cell:before {
        display: none;
    }

    .bui-table__cell {
        display: table-cell;
        vertical-align: top;
        border: 0;
    }

    .bui-table__cell--left {
        text-align: left;
    }

    .rtl .bui-table__cell--left,
    [dir="rtl"] .bui-table__cell--left {
        text-align: right;
    }

    .bui-table__cell--center {
        text-align: center;
    }

    .bui-table__cell--right {
        text-align: right;
    }

    .rtl .bui-table__cell--right,
    [dir="rtl"] .bui-table__cell--right {
        text-align: left;
    }

    .bui-table__row {
        display: table-row;
        margin: 0;
        border: 1px solid #E6E6E6;
    }

    .bui-table__cell--row-head {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    }

    .bui-table__cell--head {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
        text-align: left;
    }

    .rtl .bui-table__cell--head,
    [dir="rtl"] .bui-table__cell--head {
        text-align: right;
    }

    /* no-border / nested table */
    .bui-table--no-border {
        border: 0;
    }

    .bui-table--no-border .bui-table__cell:first-child {
        padding-left: 0;
    }

    .rtl .bui-table--no-border .bui-table__cell:first-child,
    [dir="rtl"] .bui-table--no-border .bui-table__cell:first-child {
        padding-left: 16px;
        padding-right: 0;
    }

    .bui-table--no-border .bui-table__cell:last-child {
        padding-right: 0;
    }

    .rtl .bui-table--no-border .bui-table__cell:last-child,
    [dir="rtl"] .bui-table--no-border .bui-table__cell:last-child {
        padding-right: 16px;
        padding-left: 0;
    }

    .bui-table--no-border .bui-table__cell--head {
        padding-top: 0;
    }

    .bui-table--no-border .bui-table__row {
        border-left: 0;
        border-right: 0;
    }

    .bui-table--no-border .bui-table__head .bui-table__row {
        border-top: 0;
    }

    .bui-table--no-border .bui-table__body .bui-table__row:last-child {
        border-bottom: 0;
    }

    .bui-table--no-border .bui-table__body .bui-table__row:last-child .bui-table__cell {
        padding-bottom: 0;
    }

    .bui-table--no-border .bui-table__body .bui-table__row-expansion {
        border-bottom: 0;
    }

    /* Sticky header */
    .bui-table__head--sticky .bui-table__row {
        background-color: #fff;
    }

    .bui-table__head--sticky .bui-table__cell--head {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        background-color: inherit !important;
        z-index: 1;
    }

    .bui-table__head--sticky .bui-table__cell--head:after {
        position: absolute;
        width: 100%;
        bottom: -1px;
        left: 0;
        content: '';
        border-bottom: 1px solid #E6E6E6;
    }

    .rtl .bui-table__head--sticky .bui-table__cell--head:after,
    [dir="rtl"] .bui-table__head--sticky .bui-table__cell--head:after {
        left: auto;
        right: 0;
    }

    .bui-table__cell--align-end {
        text-align: right;
    }

    .rtl .bui-table__cell--align-end,
    [dir="rtl"] .bui-table__cell--align-end {
        text-align: left;
    }

    /*
    SELECTABLE TABLE
    */

    .bui-table__cell--check {
        float: none;
        width: 16px;
        padding-left: 16px;
        padding-right: 0;
    }

    .rtl .bui-table__cell--check,
    [dir="rtl"] .bui-table__cell--check {
        padding-right: 16px;
        padding-left: 0;
        float: none;
    }

    .bui-table__cell--more {
        display: table-cell;
        text-align: right;
        position: relative;
        margin-top: 2px;
    }

    .rtl .bui-table__cell--more,
    [dir="rtl"] .bui-table__cell--more {
        text-align: left;
    }

    .bui-table__cell--more .bui-dropdown__button--more {
        -webkit-transform: translate(0, 2px);
                transform: translate(0, 2px);
    }

    .bui-table__row-expansion {
        display: table-row;
        border: none;
        margin: 0;
        border-bottom: 1px solid #E6E6E6;
    }

    .bui-table__cell--expand-trigger {
        float: none;
        width: 0;
    }

    .bui-table__expand-icon-label {
        line-height: 26px;
    }

    .bui-table__expand-icon {
        display: inline-block;
    }

    .bui-table__row--expanded .bui-table__expand-icon {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

    .bui-table__row.bui-table__row--expandable {
        border-bottom: 0;
    }

    .bui-table__row--expandable .bui-table__cell--row-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }


    /* We don't have collapsed cells on desktop */
    .bui-table__row-toggle {
        display: none;
    }

    .bui-table--initialized .bui-table__cell--collapsible,
    .bui-table--initialized .bui-table__row--expanded .bui-table__cell--collapsible {
        display: table-cell;
    }

    .bui-table__row--selected .bui-table__cell {
        border-color: #EBF3FF;
        background-color: #EBF3FF;
    }

    .bui-table--compact .bui-table__body .bui-table__cell {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .bui-table--compact .bui-table__body .bui-table__cell:first-child {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .bui-table--compact .bui-table__body .bui-table__cell:last-child {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .bui-table--compact .bui-table__row--expanded + .bui-table__row-expansion .bui-table__row-expansion-content {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
