:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    color: #333333;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    padding: 16px;
}
a.bui-card {
    text-decoration: none;
    color: #000;
}
.bui-card:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-card__image {
    width: 72px;
    height: 72px;
    margin-right: 16px;
    border: none;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: block;
}
.rtl .bui-card__image,
[dir="rtl"] .bui-card__image {
    margin-right: 0;
    margin-left: 16px;
}
.bui-card__content {
    max-width: 100%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.bui-card__title,
.bui-card__subtitle {
    margin: 0;
    padding: 0;
}
.bui-card__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.bui-card__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    color: #6B6B6B;
}
.bui-card__slot {
    margin-top: 16px;
}
.bui-card__slot:first-child {
    margin-top: 0;
}
.bui-card__text {
    margin: 16px 0 0;
}
.bui-card__text:first-child {
    margin-top: 0;
}
.bui-card__actions {
    padding-top: 16px;
}
@media (min-width: 576px) {
    .bui-card--size-large {
        padding: 24px;
    }
}
.bui-card--media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
}
.bui-card--media .bui-card__content,
.bui-card--media.bui-card--size-large .bui-card__content {
    padding: 16px;
}
.bui-card__image-container {
    position: relative;
    border-radius: 2px 2px 0 0;
    height: 240px;
    overflow: hidden;
}
.bui-card--media .bui-card__image {
    width: 100%;
    height: 100%;
    margin: 0;
    -o-object-fit: cover;
       object-fit: cover;
}
.bui-card--media-alt .bui-card__header {
    position: absolute;
    padding: 16px;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), color-stop(50%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
}
.bui-card--media-alt .bui-card__header > .bui-card__title,
.bui-card--media-alt .bui-card__header > .bui-card__subtitle,
.bui-card--media-full .bui-card__header > .bui-card__title,
.bui-card--media-full .bui-card__header > .bui-card__subtitle {
    color: #fff;
}
.bui-card--media-full {
    padding-bottom: 0;
    border: 0;
}
.bui-card--media-full > .bui-card__image-container {
    border-radius: 2px;
}
.bui-card--media-full .bui-card__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
}
.bui-card--transparent {
    background-color: transparent;
    border: none;
}
.bui-card--transparent .bui-card__content,
.bui-card--transparent.bui-card--size-large .bui-card__content {
    padding: 16px 0 0;
}
.bui-card--transparent .bui-card__image-container {
    border-radius: 2px;
}
.bui-card--transparent .bui-card__image {
    margin-left: 0;
    margin-right: 16px;
}
.rtl .bui-card--transparent .bui-card__image,
[dir="rtl"] .bui-card--transparent .bui-card__image {
    margin-right: 0;
    margin-left: 16px;
}
.bui-card--transparent.bui-card--media .bui-card__image {
    margin: 0;
}
/* Card group */
.bui-card-group > .bui-card + .bui-card {
    border-top: none;
}
.bui-card-group > .bui-card {
    border-radius: 0;
}
.bui-card-group > .bui-card:first-child {
    border-radius: 2px 2px 0 0;
}
.bui-card-group > .bui-card:last-child {
    border-radius: 0 0 2px 2px;
}
/* Card group */
.bui-card-group > .bui-card + .bui-card {
    border-top: none;
}
.bui-card-group > .bui-card {
    border-radius: 0;
}
.bui-card-group > .bui-card:first-child {
    border-radius: 2px 2px 0 0;
}
.bui-card-group > .bui-card:last-child {
    border-radius: 0 0 2px 2px;
}
