:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-link {
    color: #0071C2;
    text-decoration: underline;
    cursor: pointer;
}
.bui-link--primary,
.bui-link--primary:link,
.bui-link--secondary,
.bui-link--secondary:link {
    text-decoration: none;
}
.bui-link:hover,
.bui-link:focus,
.bui-link:active {
    color: #0071C2;
    text-decoration: underline;
}
.bui-link:focus {
  outline: 3px solid rgba(0, 113, 194, 0.3);
  outline-offset: 2px;
  -moz-outline-radius: 2px;
}
.bui-link:visited {
    color: #005C9D;
}
.bui-link:active {
    color: #005C9D;
}
.bui-link--secondary,
.bui-link--secondary:hover,
.bui-link--secondary:focus,
.bui-link--secondary:active,
.bui-link--secondary:visited {
    color: #6B6B6B;
}
/* Override for button elements of bui-link */
button.bui-link {
    border: 0;
    background: none;
    padding: 0;
    font-size: inherit;
}
.bui-link[disabled],
.bui-link[disabled]:focus,
.bui-link[disabled]:visited,
.bui-link[disabled]:active,
.bui-link[disabled]:hover {
    color: #BDBDBD;
    cursor: not-allowed;
    text-decoration: none;
}
