:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-modal {
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 400;
    padding: 24px;
    width: 576px;
    -webkit-box-sizing: initial;
            box-sizing: initial;
    text-align: left;
    -webkit-transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.bui-modal--active {
    opacity: 1;
    -webkit-transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
            transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.rtl .bui-modal,
[dir="rtl"] .bui-modal {
    text-align: right;
}
.bui-modal__inner {
    position: relative;
    border-radius: 2px;
    background: #fff;
    -webkit-box-shadow: 0 0 8px #000;
            box-shadow: 0 0 8px #000;
}
.bui-modal__slot {
    outline: none;
}
.bui-modal__close {
    display: inline-block;
    position: absolute;
    height: 24px;
    width: 24px;
    top: 16px;
    right: 16px;
    margin-bottom: 0;
    padding: 0;
    text-decoration: none;
    color: #333333;
    opacity: 0.4;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: none;
    border: none;
}
.rtl .bui-modal__close,
[dir="rtl"] .bui-modal__close {
    right: auto;
    left: 16px;
}
.bui-modal__close:hover,
.bui-modal__close:focus {
    opacity: 0.8;
}
.bui-modal__close:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-modal__close:active {
    opacity: 1;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
}
.bui-modal__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
    margin: 0 32px 8px 0;
}
.bui-modal__title:last-child {
    margin-bottom: 0;
}
.bui-modal__heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.bui-modal__paragraph {
    margin: 0;
    word-break: break-word;
}
.bui-modal__paragraph + .bui-modal__paragraph {
    margin-top: 8px;
}
.rtl .bui-modal__title,
[dir="rtl"] .bui-modal__title {
    margin-right: 0;
    margin-left: 32px;
}
.bui-modal__header,
.bui-modal__body,
.bui-modal__footer {
    display: block;
    padding: 24px;
}
.bui-modal__header + .bui-modal__footer {
    padding-top: 0;
}
.bui-modal__header-slot {
    margin-top: 8px;
}
.bui-modal__header-slot {
    margin-top: 0;
}
@media (max-width: 575px) {
    .bui-modal {
        width: 100%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
}

