:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-input-select {
    position: relative;
}
.bui-input-select .bui-form__control {
    padding-right: 32px;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    min-width: 64px;
    z-index: 100;
}
.bui-input-select .bui-form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-has-error .bui-input-select .bui-form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
          box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
}
.bui-input-select__icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    fill: #333333;
    position: absolute;
    pointer-events: none;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.bui-input-select .bui-form__control[disabled] {
    color: #BDBDBD;
}
.bui-input-select .bui-form__control[disabled] ~ .bui-input-select__icon {
    fill: #BDBDBD;
}
.rtl .bui-input-select .bui-form__control,
[dir="rtl"] .bui-input-select .bui-form__control {
    padding-left: 32px;
    padding-right: 8px;
}
.rtl .bui-input-select__icon,
[dir="rtl"] .bui-input-select__icon {
    right: auto;
    left: 8px;
}
/* IE10 and higher */
.bui-input-select .bui-form__control::-ms-expand {
    display: none;
}
