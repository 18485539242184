:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-calendar__main {
    position: relative;
}
.bui-calendar__month {
    text-align: center;
    margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.bui-calendar__control {
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0.4;
    padding: 0;
    background: none;
    border: 0;
}
.bui-calendar__control:hover,
.bui-calendar__control:focus {
    opacity: 0.8;
}
.bui-calendar__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-calendar__control--hidden {
    display: none;
}
.bui-calendar__control--prev {
    left: 0;
}
.rtl .bui-calendar__control--prev,
[dir="rtl"] .bui-calendar__control--prev {
    left: auto;
    right: 0;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.bui-calendar__control--next {
    right: 0;
}
.rtl .bui-calendar__control--next,
[dir="rtl"] .bui-calendar__control--next {
    right: auto;
    left: 0;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.bui-calendar__month {
    font-weight: 700;
}
.bui-calendar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.bui-calendar__wrapper {
    padding-left: 16px;
}
.bui-calendar__wrapper:first-child {
    padding-left: 0;
}
.bui-calendar__dates {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
}
.bui-calendar__day-name {
    font-weight: 400;
    color: #6B6B6B;
}
.bui-calendar__date {
    cursor: pointer;
}
.bui-calendar__date,
.bui-calendar__day-name {
    height: 36px;
    line-height: 36px;
    text-align: center;
    padding: 0;
    border-spacing: 0;
}
.bui-calendar__date:hover {
    background: #E6E6E6;
}
.bui-calendar__date:focus {
    position: relative;
    z-index: 10;
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-calendar__date--today {
    color: #0071C2;
}
.bui-calendar__date--in-range {
    background: #F5F5F5;
}
.bui-calendar__date--selected,
.bui-calendar__date--selected:hover {
    color: #fff;
    background: #0071C2;
}
.bui-calendar__date--disabled,
.bui-calendar__date--disabled:hover,
.bui-calendar__date--disabled:focus {
    color: #BDBDBD;
    background: none;
    cursor: default;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bui-calendar__date--empty,
.bui-calendar__date--empty:hover {
    background: none;
    cursor: default;
}
.bui-calendar__display {
    border-top: 1px solid #E6E6E6;
    text-align: center;
    margin-top: 8px;
    padding-top: 8px;
}
.bui-calendar__display:empty {
    display: none;
}
.bui-calendar__vertical-header {
    position: absolute;
    padding: 0 16px;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
}
.bui-calendar__vertical-day-names {
    width: 100%;
    table-layout: fixed;
}
.bui-calendar--vertical .bui-calendar__content {
    display: block;
    overflow: auto;
    padding: 32px 16px 16px;
    max-height: 340px;
}
.bui-calendar--vertical .bui-calendar__wrapper {
    padding: 16px 0 0;
}
.bui-calendar--vertical .bui-calendar__wrapper:first-child {
    padding-top: 0;
}
.bui-calendar--vertical .bui-calendar__control {
    display: none;
}
.bui-calendar--vertical .bui-calendar__month {
    text-align: right;
}
.rtl .bui-calendar--vertical .bui-calendar__month,
[dir="rtl"] .bui-calendar--vertical .bui-calendar__month {
    text-align: left;
}
