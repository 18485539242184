[class^=bui-],
[class^=bui-]:before,
[class^=bui-]:after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/* Group */

.bui-group > * {
    margin-top: 8px !important;
}

.bui-group > *:first-child {
    margin-top: 0 !important;
}

.bui-group--inline {
    margin: -8px 0 0 -4px;
}

.bui-group--inline > *,
.bui-group--inline > *:first-child {
    display: inline-block !important;
    vertical-align: top !important;
    margin: 8px 0 0 4px !important;
}

.bui-group--large > * {
    margin-top: 16px !important;
}

.bui-group--inline.bui-group--large {
    margin: -16px 0 0 -16px;

}

.bui-group--inline.bui-group--large > *,
.bui-group--inline.bui-group--large > *:first-child {
    margin: 16px 0 0 16px !important;
}

/* Spacers */

.bui-spacer { margin-bottom: 24px !important; }

.bui-spacer--smaller { margin-bottom: 2px !important; }

.bui-spacer--small { margin-bottom: 4px !important; }

.bui-spacer--medium { margin-bottom: 8px !important; }

.bui-spacer--large { margin-bottom: 16px !important; }

.bui-spacer--larger { margin-bottom: 24px !important; }

.bui-spacer--largest { margin-bottom: 32px !important; }
