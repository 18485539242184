:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-empty-state {
    max-width: 384px;
    text-align: center;
    margin: 0 auto;
}
.bui-empty-state__icon {
    display: block;
    margin: 0 auto 24px;
    fill: #333333;
    width: 72px;
    height: 72px;
    opacity: .6;
}
.bui-empty-state__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
    margin: 0 0 8px;
}
.bui-empty-state__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
    margin: 0 0 24px;
}
.bui-empty-state__text:last-child {
    margin-bottom: 0;
}
