:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
/* Layout & display
============================================= */
.bui-u-show,
.u-show {
    display: block !important;
}
.bui-u-full-width,
.u-full-width {
    display: block !important;
    width: 100% !important;
}
.bui-u-hidden,
.u-hidden {
    display: none !important;
}
.bui-u-inline,
.u-inline {
    display: inline-block !important;
    width: auto !important;
}
@media (max-width: 575px) {
    .bui-u-show\@small,
    .u-show\@small {
        display: block !important;
    }

    .bui-u-hidden\@small,
    .u-hidden\@small {
        display: none !important;
    }

    .bui-u-inline\@small,
    .u-inline\@small {
        display: inline-block !important;
        width: auto !important;
    }

    .bui-u-full-width\@small,
    .u-full-width\@small {
        display: block !important;
        width: 100% !important;
    }
}
@media (min-width: 576px) {
    .bui-u-show\@medium,
    .u-show\@medium {
        display: block !important;
    }

    .bui-u-hidden\@medium,
    .u-hidden\@medium {
        display: none !important;
    }

    .bui-u-inline\@medium,
    .u-inline\@medium {
        display: inline-block !important;
        width: auto !important;
    }

    .bui-u-full-width\@medium,
    .u-full-width\@medium {
        display: block !important;
        width: 100% !important;
    }
}
@media (min-width: 992px) {
    .bui-u-show\@large,
    .u-show\@large {
        display: block !important;
    }

    .bui-u-hidden\@large,
    .u-hidden\@large {
        display: none !important;
    }

    .bui-u-inline\@large,
    .u-inline\@large {
        display: inline-block !important;
        width: auto !important;
    }

    .bui-u-full-width\@large,
    .u-full-width\@large {
        display: block !important;
        width: 100% !important;
    }
}
@media (min-width: 1170px) {
    .bui-u-show\@huge,
    .u-show\@huge {
        display: block !important;
    }

    .bui-u-hidden\@huge,
    .u-hidden\@huge {
        display: none !important;
    }

    .bui-u-inline\@huge,
    .u-inline\@huge {
        display: inline-block !important;
        width: auto !important;
    }

    .bui-u-full-width\@huge,
    .u-full-width\@huge {
        display: block !important;
        width: 100% !important;
    }
}
.bui-u-full-height,
.u-full-height {
    height: 100%;
}
@media (max-width: 575px) {
    .bui-u-bleed\@small,
    .u-bleed\@small {
        margin-left: -16px !important;
        margin-right: -16px !important;
        border-radius: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }
}
/* Text alignment
============================================= */
.bui-u-text-left,
.u-text-left {
    text-align: left !important;
}
.bui-u-text-center,
.u-text-center {
    text-align: center !important;
}
.bui-u-text-right,
.u-text-right {
    text-align: right !important;
}
.rtl .bui-u-text-left,
[dir="rtl"] .bui-u-text-left,
.rtl .u-text-left,
[dir="rtl"] .u-text-left {
    text-align: right !important;
}
.rtl .bui-u-text-right,
[dir="rtl"] .bui-u-text-right,
.rtl .u-text-right,
[dir="rtl"] .u-text-right {
    text-align: left !important;
}
@media (max-width: 575px) {
    .bui-u-text-left\@small {
        text-align: left !important;
    }

    .rtl .bui-u-text-left\@small,
    [dir="rtl"] .bui-u-text-left\@small {
        text-align: right !important;
    }

    .bui-u-text-right\@small {
        text-align: right !important;
    }

    .rtl .bui-u-text-right\@small,
    [dir="rtl"] .bui-u-text-right\@small {
        text-align: left !important;
    }

    .bui-u-text-center\@small {
        text-align: center !important;
    }
}
@media (min-width: 576px) {
    .bui-u-text-left\@medium {
        text-align: left !important;
    }

    .rtl .bui-u-text-left\@medium,
    [dir="rtl"] .bui-u-text-left\@medium {
        text-align: right !important;
    }

    .bui-u-text-right\@medium {
        text-align: right !important;
    }

    .rtl .bui-u-text-right\@medium,
    [dir="rtl"] .bui-u-text-right\@medium {
        text-align: left !important;
    }

    .bui-u-text-center\@medium {
        text-align: center !important;
    }
}
@media (min-width: 992px) {
    .bui-u-text-left\@large {
        text-align: left !important;
    }

    .rtl .bui-u-text-left\@large,
    [dir="rtl"] .bui-u-text-left\@large {
        text-align: right !important;
    }

    .bui-u-text-right\@large {
        text-align: right !important;
    }

    .rtl .bui-u-text-right\@large,
    [dir="rtl"] .bui-u-text-right\@large {
        text-align: left !important;
    }

    .bui-u-text-center\@large {
        text-align: center !important;
    }
}
@media (min-width: 1170px) {
    .bui-u-text-left\@huge {
        text-align: left !important;
    }

    .rtl .bui-u-text-left\@huge,
    [dir="rtl"] .bui-u-text-left\@huge {
        text-align: right !important;
    }

    .bui-u-text-right\@huge {
        text-align: right !important;
    }

    .rtl .bui-u-text-right\@huge,
    [dir="rtl"] .bui-u-text-right\@huge {
        text-align: left !important;
    }

    .bui-u-text-center\@huge {
        text-align: center !important;
    }
}
/* Accessibility
============================================= */
.bui-u-sr-only,
.u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    border: 0;
}
.bui-u-sr-only-focusable:active,
.bui-u-sr-only-focusable:focus,
.u-sr-only-focusable:active,
.u-sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
            clip-path: none;
}
/* Print
============================================= */
.bui-u-visible-print-block,
.bui-u-visible-print-inline-block,
.bui-u-visible-print-inline,
.u-visible-print-block,
.u-visible-print-inline-block,
.u-visible-print-inline {
    display: none !important;
}
@media print {
    .bui-u-hidden-print,
    .u-hidden-print {
        display: none !important;
    }

    .bui-u-visible-print-block,
    .u-visible-print-block {
        display: block !important;
    }

    .bui-u-visible-print-inline-block,
    .u-visible-print-inline-block {
        display: inline-block !important;
    }

    .bui-u-visible-print-inline,
    .u-visible-print-inline {
        display: inline !important;
    }
}
/* Deprecated
============================================= */
.bui-u-pull-start,
.u-pull-start {
    float: left !important;
}
.bui-u-pull-end,
.u-pull-end {
    float: right !important;
}
.rtl .bui-u-pull-start,
[dir="rtl"] .bui-u-pull-start,
.rtl .u-pull-start,
[dir="rtl"] .u-pull-start {
    float: right !important;
}
.rtl .bui-u-pull-end,
[dir="rtl"] .bui-u-pull-end,
.rtl .u-pull-end,
[dir="rtl"] .u-pull-end {
    float: left !important;
}
.rtl .bui-u-rtl-pulled-start,
[dir="rtl"] .bui-u-rtl-pulled-start,
.rtl .u-rtl-pulled-start,
[dir="rtl"] .u-rtl-pulled-start {
    float: right !important;
}
.rtl .bui-u-rtl-pulled-end,
[dir="rtl"] .bui-u-rtl-pulled-end,
.rtl .u-rtl-pulled-end,
[dir="rtl"] .u-rtl-pulled-end {
    float: left !important;
}
.rtl .bui-u-rtl-text-right,
[dir="rtl"] .bui-u-rtl-text-right,
.rtl .u-rtl-text-right,
[dir="rtl"] .u-rtl-text-right {
    text-align: right !important;
}
.rtl .bui-u-rtl-text-left,
[dir="rtl"] .bui-u-rtl-text-left,
.rtl .u-rtl-text-left,
[dir="rtl"] .u-rtl-text-left {
    text-align: left !important;
}
.bui-u-clearfix:before,
.bui-u-clearfix:after,
.u-clearfix:before,
.u-clearfix:after {
    content: " ";
    display: table;
    line-height: 0;
}
.bui-u-clearfix:after,
.u-clearfix:after {
    clear: both;
}
