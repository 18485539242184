:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-tab--box {
    margin: -16px -16px 16px;
}
.bui-tab__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    border-bottom: 1px solid #E6E6E6;
    list-style: none;
    margin: 0;
    padding: 0;
}
.bui-tab__nav:after {
    content: '';
    display: table;
    clear: both;
}
.bui-tab__item {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.bui-tab__link {
    margin: 0 16px 0 0;
    display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
    border: 0;
    background: none;
    color: #6B6B6B;
    padding: 16px 0;
    text-decoration: none;
    cursor: pointer;
    max-width: none;
    position: relative;
}
.bui-tab__link:focus {
  outline: 3px solid rgba(0, 113, 194, 0.3);
  outline-offset: 2px;
  -moz-outline-radius: 2px;
}
.rtl .bui-tab__link,
[dir="rtl"] .bui-tab__link {
    margin-left: 16px;
    margin-right: 0;
}
.bui-tab__link:hover {
    color: #0071C2;
    text-decoration: none;
}
.bui-tab__link:link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
    text-decoration: none;
}
.bui-tab__link--selected {
    color: #333333;
}
.bui-tab__link--selected:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background: #0071C2;
}
.bui-tab__link--selected:hover {
    background-color: transparent;
}
.bui-tab__link--selected:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bui-tab__link--disabled,
.bui-tab__link--disabled:hover,
.bui-tab__link--disabled:focus,
.bui-tab__link--disabled:active {
    background-color: transparent;
    color: #BDBDBD;
    outline: none;
    cursor: not-allowed;
}
.bui-tab__item--hidden {
    display: none;
}
.bui-tab__item--more .bui-tab__link {
    margin: 0;
}
.bui-tab__item--more .bui-tab__link:after {
    content: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='%23383838'><path d='M12 20.09a1.24 1.24 0 0 1-.88-.36L6 14.61a.75.75 0 1 1 1.06-1.06L12 18.49l4.94-4.94A.75.75 0 0 1 18 14.61l-5.12 5.12a1.24 1.24 0 0 1-.88.36zm6-9.46a.75.75 0 0 0 0-1.06l-5.12-5.11a1.24 1.24 0 0 0-1.76 0L6 9.57a.75.75 0 0 0 0 1.06.74.74 0 0 0 1.06 0L12 5.7l4.94 4.93a.73.73 0 0 0 .53.22.74.74 0 0 0 .53-.22z'/></svg>");
    width: 16px;
    height: 16px;
    margin-left: 4px;
}
.rtl .bui-tab__item--more .bui-tab__link:after,
[dir="rtl"] .bui-tab__item--more .bui-tab__link:after {
    margin-right: 8px;
    margin-left: 0;
}
.bui-tab__more-items {
    position: absolute;
}
.bui-tab__item--more {
    display: none;
    position: relative;
    margin: 0;
}
.bui-tab__item--more .bui-tab__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.bui-tab__item--more-active {
    display: block;
}
.bui-tab__dropdown-trigger {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    border: 0;
}
.bui-tab__dropdown-trigger:focus + .bui-tab__link {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
}
.bui-tab__content {
    display: none;
}
.bui-tab__content--selected {
    display: block;
}
.bui-tab__dropdown {
    min-width: 160px;
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
}
.bui-tab__item--more-dropdown-active .bui-tab__dropdown {
    display: block;
}
