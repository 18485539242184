:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-divider {
    margin: 0;
    border: 0;
    border-top: 1px solid #E6E6E6;
}
.bui-divider--light {
    border-color: #F5F5F5;
}
