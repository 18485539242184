:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-list {
    padding-left: 16px;
    margin: 0 0 16px 0;
}
.bui-list:last-child {
    margin-bottom: 0;
}
.rtl .bui-list,
[dir="rtl"] .bui-list {
    padding-left: 0;
    padding-right: 16px;
}
.bui-list__item {
    margin-bottom: 16px;
}
.bui-list__item:last-child {
    margin-bottom: 0;
}
.bui-list--text {
    list-style-type: none;
    padding-left: 0;
}
.rtl .bui-list--text,
[dir="rtl"] .bui-list--text {
    padding-right: 0;
}
.bui-list--divided > .bui-list__item {
    margin: 0;
    padding: 16px 0;
    border-bottom: 1px solid #E6E6E6;
}
.bui-list--divided > .bui-list__item:first-child {
    padding-top: 0;
}
.bui-list--divided > .bui-list__item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}
.bui-list--icon > .bui-list__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.bui-list__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 16px;
}
.rtl .bui-list__icon,
[dir="rtl"] .bui-list__icon {
    margin-left: 16px;
    margin-right: 0;
}
.bui-list__body {
    margin: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.bui-list__description {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.bui-list__description-title,
.bui-list__description-subtitle {
    display: block;
}
.bui-list__description-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.bui-list__description-subtitle {
    color: #6B6B6B;
}
.bui-list__item-action {
    margin-left: 16px;
}
.rtl .bui-list__item-action,
[dir="rtl"] .bui-list__item-action {
    margin-left: 0;
    margin-right: 16px;
}
@media (max-width: 575px) {
    .bui-list__body {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }

    .bui-list__description {
        width: 100%;
    }

    .rtl .bui-list__item-action,
    [dir="rtl"] .bui-list__item-action,
    .bui-list__item-action {
        margin: 16px 0 0;
        width: 100%;
    }
}
