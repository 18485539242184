:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-accordion {
    list-style: none;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    margin: 0;
    padding: 0;
    color: #333333;
    background: #fff;
}
.bui-accordion__row + .bui-accordion__row {
    border-top: 1px solid #E6E6E6;
}
.bui-accordion__row-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: transparent;
    border: none;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
    padding: 16px;
    text-align: left;
    color: inherit;
}
.bui-accordion__row-inner:hover {
    background-color: #F5F5F5;
}
.bui-accordion__row.bui-is-active .bui-accordion__row-inner:hover {
    background-color: transparent;
}
.bui-accordion__row-inner:active,
.bui-accordion__row-inner:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none;
    color: inherit;
}
.rtl .bui-accordion__row-inner,
[dir="rtl"] .bui-accordion__row-inner {
    text-align: right;
}
.bui-accordion__row-header {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.bui-accordion__title,
.bui-accordion__subtitle {
    margin: 0;
}
.bui-accordion__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.bui-accordion__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-accordion__icon-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    fill: #333333;
    padding-left: 16px;
}
.rtl .bui-accordion__icon-container,
[dir="rtl"] .bui-accordion__icon-container {
    padding-left: 0;
    padding-right: 16px;
}
.bui-accordion__icon {
    height: 24px;
    width: 24px;
}
.bui-accordion__content {
    display: none;
    padding: 0 16px 16px;
}
.bui-accordion__row.bui-is-active .bui-accordion__content {
    display: block;
}
.bui-accordion__row.bui-is-active .bui-accordion__icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
