:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-overlay {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 200;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    border: none;
    padding: 0;
    position: fixed;
    background: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: background-color 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: background-color 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
}
.bui-overlay__wrapper {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed;
}
.bui-overlay__align {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
.bui-overlay__content {
    display: inline-block;
    vertical-align: top;
}
.bui-overlay--active {
    -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 575px) {
    .bui-overlay__content {
        width: 100%;
    }
}
