:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
@-webkit-keyframes bui-spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
}
@keyframes bui-spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
}
.bui-spinner {
    width: 24px;
    height: 24px;
    position: relative;
    -webkit-animation: bui-spinner 1s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
            animation: bui-spinner 1s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.bui-spinner__inner:after,
.bui-spinner__inner:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 3px solid #E6E6E6;
    border-radius: 50%;
}
.bui-spinner__inner:after {
    border: 3px solid transparent;
    border-top-color: #0071C2;
    border-right-color: #0071C2;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.bui-spinner:before,
.bui-spinner:after {
    content: '';
    width: 3px;
    height: 3px;
    background: #0071C2;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
}
.bui-spinner:before {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.bui-spinner:after {
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.bui-spinner--light .bui-spinner__inner:before {
    opacity: 0.3;
}
.bui-spinner--light .bui-spinner__inner:after {
    border-top-color: #fff;
    border-right-color: #fff;
}
.bui-spinner--light:after,
.bui-spinner--light:before {
    background: #fff;
}
.bui-spinner--size-small {
    width: 16px;
    height: 16px;
}
.bui-spinner--size-small .bui-spinner__inner:before,
.bui-spinner--size-small .bui-spinner__inner:after {
    border-width: 2px;
}
.bui-spinner--size-small:before,
.bui-spinner--size-small:after {
    width: 2px;
    height: 2px;
}
.bui-spinner--size-large {
    width: 32px;
    height: 32px;
}
.bui-spinner--size-large .bui-spinner__inner:before,
.bui-spinner--size-large .bui-spinner__inner:after {
    border-width: 4px;
}
.bui-spinner--size-large:before,
.bui-spinner--size-large:after {
    width: 4px;
    height: 4px;
}
.bui-spinner--size-larger {
    width: 48px;
    height: 48px;
}
.bui-spinner--size-larger .bui-spinner__inner:before,
.bui-spinner--size-larger .bui-spinner__inner:after {
    border-width: 6px;
}
.bui-spinner--size-larger:before,
.bui-spinner--size-larger:after {
    width: 6px;
    height: 6px;
}
.bui-spinner--size-largest {
    width: 72px;
    height: 72px;
}
.bui-spinner--size-largest .bui-spinner__inner:before,
.bui-spinner--size-largest .bui-spinner__inner:after {
    border-width: 8px;
}
.bui-spinner--size-largest:before,
.bui-spinner--size-largest:after {
    width: 8px;
    height: 8px;
}
