:root {
    /* Easings */ /* Quarts */ /* Sine */

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
}
:root {
    /* Grid */

    /* Animation */

    /* z-index */
}
.bui-breadcrumb {
    padding: 0;
    margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.bui-breadcrumb__list {
    padding: 0;
    list-style-type: none;
}
.bui-breadcrumb__text,
.bui-breadcrumb__title,
.bui-breadcrumb__icon {
    display: inline-block;
}
.bui-breadcrumb__title {
    margin: 0;
}
.bui-breadcrumb__icon {
    height: 16px;
    width: 16px;
    margin: 1px 4px;
    vertical-align: top;
}
.rtl .bui-breadcrumb__icon,
[dir="rtl"] .bui-breadcrumb__icon {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.bui-breadcrumb--back .bui-link--secondary {
    display: inline-block;
}
.bui-breadcrumb--back .bui-breadcrumb__icon {
    margin-left: 0;
    fill: #333333;
}
.rtl .bui-breadcrumb--back .bui-breadcrumb__icon,
[dir="rtl"] .bui-breadcrumb--back .bui-breadcrumb__icon {
    margin-right: 0;
    margin-left: 4px;
}
@media (max-width: 575px) {
    .bui-breadcrumb__icon {
        margin: 1px 0;
    }
}
