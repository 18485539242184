.bui-form__control {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #E6E6E6;
  display: block;
  font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
}
[dir="rtl"] .bui-form__control,
.rtl .bui-form__control {
    text-align: right;
}
.bui-form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
          box-shadow: 0 0 0 3px rgba(0, 113, 194, 0.3);
  border-color: #0071C2;
}
input.bui-form__control[disabled],
textarea.bui-form__control[disabled] {
  color: #BDBDBD;
  background-color: #F5F5F5;
}
.bui-form__control:disabled,
.bui-form__control--disabled {
  cursor: not-allowed;
}
.bui-form__control::-ms-clear {
    display: none;
}
.bui-form__control::-webkit-input-placeholder, /* WebKit, Blink, Edge */
.bui-form__control:-moz-placeholder, /* Mozilla Firefox 4 to 18 */
.bui-form__control::-moz-placeholder, /* Mozilla Firefox 19+ */
.bui-form__control:-ms-input-placeholder, /* Internet Explorer 10-11 */
.bui-form__control::-ms-input-placeholder { /* Microsoft Edge */
    color: #BDBDBD;
}
.bui-form__group {
    border: none;
    padding: 0;
    margin: 0;
}
.bui-form__label {
    display: block;
}
.bui-form__label,
.bui-form__group label {
    margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bui-form__group:last-child {
    margin-bottom: 0;
}
.bui-form__error,
.bui-form__helper {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
    margin-top: 4px;
}
.bui-form__error {
    display: none;
    color: #CC0000;
}
.bui-form__helper {
    color: #6B6B6B;
}
/* Validation */
.bui-has-error .bui-form__control {
  border-color: #CC0000;
}
.bui-has-error .bui-form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
          box-shadow: 0 0 0 3px rgba(163, 0, 0, 0.3);
  border-color: #CC0000;
}
.bui-has-error .bui-form__error {
    display: block;
}
