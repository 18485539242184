:root {
    /* Easings */
    --bui_easing-slow-in: cubic-bezier(0.895, 0.03, 0.685, 0.22); /* Quarts */
    --bui_easing-slow-out: cubic-bezier(0.165, 0.84, 0.44, 1);
    --bui_easing-slow-in-out: cubic-bezier(0.77, 0, 0.175, 1);
    --bui_easing-subtle-in: cubic-bezier(0.47, 0, 0.745, 0.715); /* Sine */
    --bui_easing-subtle-out: cubic-bezier(0.39, 0.575, 0.565, 1);
    --bui_easing-bounce-in: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    --bui_easing-bounce-out: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    /* Durations from https://www.modularscale.com/?25&px&1.6 */
    --bui_timing-instant: 98ms;
    --bui_timing-fast: 156ms;
    --bui_timing-deliberate: 250ms;
    --bui_timing-slow: 400ms;
    --bui_timing-slower: 640ms;
    --bui_timing-slowest: 1024ms;
    --bui_timing-paused: 1638ms;
}
:root {
    --bui_color_destructive_dark: #A30000;
    --bui_color_destructive: #CC0000;
    --bui_color_destructive_light: #FCB4B4;
    --bui_color_destructive_lighter: #FFEBEB;
    --bui_color_destructive_lightest: #FFF0F0;

    --bui_color_callout_dark: #BC5B01;
    --bui_color_callout: #FF8000;
    --bui_color_callout_light: #FFC489;
    --bui_color_callout_lighter: #FFF0E0;
    --bui_color_callout_lightest: #FFF8F0;

    --bui_color_complement_dark: #CD8900;
    --bui_color_complement: #FEBB02;
    --bui_color_complement_light: #FFE08A;
    --bui_color_complement_lighter: #FDF4D8;
    --bui_color_complement_lightest: #FEFBF0;

    --bui_color_constructive_dark: #006607;
    --bui_color_constructive: #008009;
    --bui_color_constructive_light: #97E59C;
    --bui_color_constructive_lighter: #E7FDE9;
    --bui_color_constructive_lightest: #F1FEF2;

    --bui_color_primary_dark: #00224F;
    --bui_color_primary: #003580;
    --bui_color_primary_light: #BAD4F7;
    --bui_color_primary_lighter: #EBF3FF;
    --bui_color_primary_lightest: #FAFCFF;

    --bui_color_grayscale_dark: #333333;
    --bui_color_grayscale: #6B6B6B;
    --bui_color_grayscale_light: #BDBDBD;
    --bui_color_grayscale_lighter: #E6E6E6;
    --bui_color_grayscale_lightest: #F5F5F5;

    --bui_color_black: #000;
    --bui_color_white: #fff;

    --bui_color_action_dark: #005C9D;
    --bui_color_action: #0071C2;
    --bui_color_action_light: #A3D7FC;
    --bui_color_action_lighter: #E4F4FF;

    --genius_color_primary: #004CB8;
}
:root {
    --bui_depth_0_box_shadow: none !important;

    --bui_depth_1_box_shadow:
        0 1px 8px 0 rgba(0, 0, 0, .12),
        0 2px 3px -1px rgba(0, 0, 0, .2);

    --bui_depth_2_box_shadow:
        0 3px 18px 0 rgba(0, 0, 0, .12),
        0 3px 5px -1px rgba(0, 0, 0, .2);

    --bui_depth_3_box_shadow:
        0 4px 15px 2px rgba(0, 0, 0, .12),
        0 5px 6px -3px rgba(0, 0, 0, .2);

    --bui_depth_4_box_shadow:
        0 6px 30px 5px rgba(0, 0, 0, .12),
        0 8px 10px -5px rgba(0, 0, 0, .2);
}
:root {
    --bui_unit_value: 8;
    --bui_unit_smaller: 2px;
    --bui_unit_small: 4px;
    --bui_unit_medium: 8px;
    --bui_unit_large: 16px;
    --bui_unit_larger: 24px;
    --bui_unit_largest: 32px;
}
:root {
    /* Grid */
    --bui_baseline: 24px;
    --bui_padding: 12px;
    --bui_negative_padding: -12px;
    --bui_medium_breakpoint: 576px;
    --bui_large_breakpoint: 992px;
    --bui_huge_breakpoint: 1200px;
    --bui_container: 1140px;

    /* Animation */
    --bui_animation_duration: 0.15s;

    /* z-index */
    --z_index_0: 0;
    --z_index_1: 100;
    --z_index_2: 200;
    --z_index_3: 300;
    --z_index_4: 400;
}
:root {
    --bui_font_smaller_size : 12px;
    --bui_font_smaller_line_height: 18px;

    --bui_font_small_size : 14px;
    --bui_font_small_line_height: 20px;

    --bui_font_medium_size: 16px;
    --bui_font_medium_line_height: 24px;

    --bui_font_large_size: 20px;
    --bui_font_large_line_height: 28px;

    --bui_font_larger_size: 24px;
    --bui_font_larger_line_height: 32px;

    --bui_font_largest_size: 32px;
    --bui_font_largest_line_height: 40px;

    --bui_font_weight_normal: 400;
    --bui_font_weight_medium: 500;
    --bui_font_weight_bold: 700;

    --bui_font_stack_sans: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    --bui_font_stack_serif: "Georgia", serif;
    --bui_font_stack_mono: "Monaco", "Courier New", monospace;
    --bui_font_stack_arabic: "Geeza Pro", "Tahoma", var(--bui_font_family_sans);
}